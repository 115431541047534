import React, { useState, useEffect, createRef } from 'react';
import PlaceDetails from '../PlaceDetails/PlaceDetails.js'
import { CircularProgress, Grid, styled } from '@mui/material';

const StyledList = styled(Grid)({
  height: '50vh',
  overflow: 'auto'
})

const List = ({ places, childClicked, isLoading }) => {
  const [type, setType] = useState('restaurants');
  const [rating, setRating] = useState('');
  const [elRefs, setElrefs] = useState([]);


  useEffect(() => {
    const refs = Array(places?.length).fill().map((_, i) => elRefs[i] || createRef())
    setElrefs(refs)
  }, [places])

  return (
    <div>
      {isLoading ? (<div>
        <CircularProgress size="5rem" />
      </div>)
        :
        (
          <>
            {/* <FormControl className={classes.formControl}>
            <InputLabel id="rating">Rating</InputLabel>
            <Select value={rating} onChange={(e)=> setRating(e.target.value)}>
              <MenuItem value="0">All</MenuItem>
              <MenuItem value="3">Above 3.0</MenuItem>
              <MenuItem value="4">Above 4.0</MenuItem>
              <MenuItem value="4.5">Above 4.5</MenuItem>
            </Select>
      </FormControl> */}
            <StyledList container spacing={3} >
              {places?.map((place, i) => (
                <Grid item key={i} xs={12}>
                  <PlaceDetails
                    place={place}
                    selected={Number(childClicked) === i}
                    refProp={elRefs[i]}
                  />
                </Grid>
              ))}
            </StyledList>
          </>
        )}
    </div>
  )
}

export default List