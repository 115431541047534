import React from 'react'

const SafetyContainer = () => {
  return (
    <div   className=' bg-[#d2dbd8] dark:bg-slate-800 w-full h-full min-h-screen'>
        <div>

        </div>
        <h1 className="py-5 mb-5 text-5xl text-center text-gradient font-bold bg-gradient-to-r from-pink-400 via-pink-500 to-pink-800 ">Safar Safety</h1>
        <div class="container mx-auto p-4">
        <h1 class="text-center text-2xl font-bold mb-6 dark:text-white">ٱلْمُهَينُْمِ - The Preserver of Safety & Overseeing Protector</h1>
        <p class="mb-10 text-sm md:text-base lg:text-lg text-black dark:text-white mx-4 md:mx-0 text-left leading-relaxed">
                    Allah is Al-Muhaymin, the Preserver of Safety and the Overseeing Protector. 
                    As you travel, place your trust in Allah ﷻ. Remember to make du'a, using Safar Dua, for safety and well-being before you embark on your journey. 
                    Understand that while taking practical precautions is essential, ultimate protection and security come from relying on Allah ﷻ. Stay connected to your faith by performing your prayers on time and reciting protective supplications. Keeping a strong connection with Allah ﷻ will provide you with peace of mind and spiritual security throughout your travels.
                </p>
        </div>

        <div class="container mx-auto p-4">
        <h1 class="text-center text-2xl font-bold mb-6 dark:text-white">Do your research on the location</h1>
        <p class="mb-10 mt-3 text-md md:text-base lg:text-lg text-black dark:text-white mx-4 md:mx-0 text-left leading-relaxed">
            Understanding the location you're going to is amongst the most important things you need to do in order to protect yourself.
            Read reviews from Safar on attractions and read blogs on other Muslims' experiences in the city.
            <br/>
            Blogs will be a feature coming to Safar in later updates, in the mean time, there are numerous travel blog pages that you can access to get authentic experience,
             which include  <a class="text-blue-500 underline" href="https://muslimtravelgirl.com/">Muslim Travel Girl</a>
            <br/>
            For British citizens, use the UK's <a class="text-blue-500 underline" href="https://www.gov.uk/foreign-travel-advice">Foreign Travel Advice</a>, and follow the specific guidelines for your chosen location.
        </p>
        </div>
        <div class="container mx-auto p-4">
        <h1 class="text-center text-2xl font-bold mb-6 dark:text-white">Keeping loved ones updated</h1>
        <p class="mb-10 text-sm md:text-base lg:text-lg text-black dark:text-white mx-4 md:mx-0 text-left leading-relaxed">
                Keeping loved ones updated on your travel plans and daily activities is essential for your safety. 
                Share your itinerary with family or friends before you leave, including details of your flights, accommodation, and key contact numbers.
                Regularly check in with someone you trust, providing updates on your whereabouts and any changes to your plans. Use messaging apps or social media to stay in touch, but avoid sharing detailed information about your location publicly. In case of an emergency, having someone aware of your schedule can expedite assistance and ensure your safety.
         </p>
        </div>
        <div class="container mx-auto p-4">
        <h1 class="text-center text-2xl font-bold mb-6 dark:text-white">Awareness of Surroundings</h1>
            <p class="mb-10 text-sm md:text-base lg:text-lg text-black dark:text-white mx-4 md:mx-0 text-left leading-relaxed">
                Always be mindful of your surroundings, especially in unfamiliar areas. Avoid using headphones or other distractions that can reduce your awareness. Stick to well-lit and populated areas, especially at night. Be cautious of anyone acting suspiciously or following you. If you feel uncomfortable, move to a safer location and seek help. Trust your instincts; if something feels off, it's better to be cautious and alert someone.
                 Inform someone you trust about your whereabouts and schedule, especially if traveling alone. Use a map or GPS discreetly to avoid looking lost or vulnerable. Avoid sharing your location or travel plans on social media in real-time. Be aware of common scams in the area and how to avoid them.
            </p>
        </div>
        <div class="container mx-auto p-4">
        <h1 class="text-center text-2xl font-bold mb-6 dark:text-white">How to Safeguard Your Hotel Room</h1>
        <div class=" dark:bg-gray-800 p-6 rounded-lg dark:text-white">
            <h2 class="text-xl font-semibold mb-4">Before You Travel</h2>
            <ul class="list-disc list-inside mb-6">
                <li>Choose reputable hotels with good reviews regarding safety and security.</li>
                <li>Check if the hotel has adequate security measures, such as surveillance cameras and 24-hour security personnel.</li>
            </ul>
            <h2 class="text-xl font-semibold mb-4">Upon Arrival</h2>
            <ul class="list-disc list-inside mb-6">
                <li>Check for functioning locks on doors and windows.</li>
                <li>Ensure that the door has a peephole and a deadbolt lock.</li>
            </ul>
            <h2 class="text-xl font-semibold mb-4">During Your Stay</h2>
            <ul class="list-disc list-inside mb-6">
                <li>Use the deadbolt lock and chain or latch lock provided by the hotel.</li>
                <li>Consider carrying a portable door lock or doorstop alarm for added security.</li>
                <li>Use the in-room safe to store valuables like passports, cash, and electronics.</li>
                <li>If the room safe is not available, ask if the hotel has a secure area for storing valuables.</li>
                <li>When you are inside the room, keep the "Do Not Disturb" sign on the door to deter staff or others from entering.</li>
                <li>Do not open the door to strangers. Verify the identity of hotel staff by calling the front desk if someone unexpected knocks on your door.</li>
                <li>Keep your room key card safe and do not share your room number with strangers.</li>
                <li>If you lose your key, inform the front desk immediately.</li>
                <li>When leaving your room, ensure windows and doors are locked.</li>
                <li>Leave a light or the TV on to make it appear that the room is occupied.</li>
            </ul>
            <h2 class="text-xl font-semibold mb-4">Emergency Preparedness</h2>
            <ul class="list-disc list-inside mb-6">
                <li>Familiarize yourself with the nearest emergency exits and evacuation routes.</li>
                <li>Inform hotel security or the front desk if you notice anything suspicious.</li>
            </ul>
        </div>
    </div>
    </div>
  )
}

export default SafetyContainer