import React from 'react';
import GoogleMapReact from 'google-map-react';
import { styled, useMediaQuery } from '@mui/material';

const getPhotoUrl = (photoReference) => {
  return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=3264&maxheight=1837&photoreference=${photoReference}&key=AIzaSyDC-u3fRWmCmLeFEFxrj9q08mE0-l4V2IE`;
};

const MapContainer = styled('div')({
  height: '85vh',
  width: '100%'
});

const MarkerContainer = styled('div')({
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  zIndex: 1,
  '&:hover': { zIndex: 2 },
});

const Map = ({ setCoordinates, coordinates, places, setBounds, setChildClicked }) => {
  const isDesktop = useMediaQuery('(min-width:600px)');

  return (
    <MapContainer>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDC-u3fRWmCmLeFEFxrj9q08mE0-l4V2IE' }}
        defaultCenter={coordinates}
        center={coordinates}
        defaultZoom={14}
        onChange={(e) => {
          setCoordinates({ lat: e.center.lat, lng: e.center.lng });
          setBounds({ ne: e.marginBounds.ne, sw: e.marginBounds.sw });
        }}
        onChildClick={(child) => {
          setChildClicked(child);
        }}
      >
        {places?.map((place, i) => (
          <MarkerContainer
            lat={Number(place.geometry.location.lat)}
            lng={Number(place.geometry.location.lng)}
            key={i}
          >

          </MarkerContainer>
        ))}
      </GoogleMapReact>
    </MapContainer>
  );
};

export default Map;



// const APIKEY = 'AIzaSyD5OAaC5LrjisDtQOlgnXAoaWvbvGguVIc';