import React, { useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { alpha, InputBase, styled, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const StyledSearch = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': { backgroundColor: alpha(theme.palette.common.white, 0.25) },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  marginBottom: '30px',
  [theme.breakpoints.up('sm')]: { marginLeft: theme.spacing(3), width: 'auto' },
})
);

const StyledSearchIcon = styled('div')({
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  root: {
    color: 'inherit'
  },
  input: {
    padding: theme.spacing(1, 1, 1, 0), 
    paddingLeft: `10%`,
    transition: theme.transitions.create('width'), 
    width: '40ch'
  }
}));

const Search = ({ setCoordinates, theme }) => {

  const [autocomplete, setAutocomplete] = useState(null);
  const onLoad = (autocomplete) => setAutocomplete(autocomplete);

  const onPlaceChanged = () => {
    const lat = autocomplete.getPlace().geometry.location.lat();
    const lng = autocomplete.getPlace().geometry.location.lng();
    setCoordinates({ lat, lng });
  }
  return (
    <div>
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <StyledSearch theme={theme}>
          <StyledSearchIcon>
            <SearchIcon />
          </StyledSearchIcon>
          <StyledInputBase theme={theme} placeholder="Search halal food worldwide..." />
        </StyledSearch>
      </Autocomplete>
    </div>
  )
}

export default Search