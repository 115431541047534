const NotFoundContainer = () => {

    return (
        <div id='error'>
            <h1 >Oops! That page can’t be found.</h1>
            <p>
                It looks like nothing was found at this location.
                Press back to go to the previous page.
            </p>
        </div>
    );
}

export default NotFoundContainer;